<template>
    <v-container fluid class="pa-0">
        <PageHeader :icon="icon" :avatar="avatar" :title="title"/>

        <v-container fluid class="pa-5 d-flex align-center justify-center" style="min-height: calc(100vh - 194px)" v-if="error.type == null && progress">
            <div class="text-center">
                <v-progress-circular :size="64" :width="6" color="primary" indeterminate></v-progress-circular>

                <div class="text-h5 mt-4">{{$t("page_errors.progress.title")}}</div>
                <div>{{$t("page_errors.progress.info")}}</div>
            </div>
        </v-container>

        <v-container fluid class="pa-5" v-if="error.type == null && !progress">
            <slot></slot>
        </v-container>

        <v-container fluid class="pa-5 d-flex align-center justify-center" style="min-height: calc(100vh - 194px)" v-if="error.type == 'network'">
            <div class="text-center">
                <v-icon size="80">mdi-alert-circle-outline</v-icon>

                <div class="text-h5">{{$t("page_errors.network.title")}}</div>
                <div>{{$t("page_errors.network.info")}}</div>
                <div class="text-caption">{{$t("page_errors.network.code")}}: {{error.code}} ({{error.msg}})</div>

                <v-btn class="mt-3" color="primary" @click="$emit('reload')">{{$t("page_errors.network.reload")}}</v-btn>
            </div>
        </v-container>
    </v-container>
</template>

<script>
import PageHeader from "./PageHeader";

export default {
    components: {
        PageHeader
    },

    props: {
        title: {
            type: String,
            default: "No name"
        },

        progress: {
            type: Boolean,
            default: false
        },

        error: {
            type: Object,
            default: () => ({
                type: null,
                msg: null,
                code: null
            })
        },

        avatar: {
            type: String,
            default: null
        },

        icon: {
            type: String,
            default: null
        }
    }
};
</script>
