<template>
    <v-dialog :fullscreen="fullscreen" background-color="#ff0000" v-model="open" persistent :max-width="maxWidth" class="pa-0 ma-0" content-class="rounded-0">
        <v-card class="rounded-0" elevation="1">
            <!-- <v-card-title class="text-h5 pt-5 pl-5 pr-5" v-if="title && !fullscreen">
                <v-icon v-if="icon">{{ icon }}</v-icon> {{ title }}
            </v-card-title>

            <v-card-title v-if="!title && !fullscreen" class="text-h5 pt-1"></v-card-title> -->

            <v-toolbar
                flat
                dark
                color="primary"
            >
                <v-toolbar-title>
                    <v-icon v-if="icon">{{ icon }}</v-icon> {{ title }}
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <slot name="toolbar"></slot>

                    <v-btn
                        v-if="hasCancelListener() && fullscreen"
                        :disabled="saveProgress"
                        dark
                        text
                        @click="$emit('cancel')"
                    >
                        <v-icon left>mdi-close</v-icon>
                        <div v-if="!cancel">{{ $t("cancel") }}</div>
                        <div v-else>{{ cancel }}</div>
                    </v-btn>

                    <v-btn
                        v-if="hasSaveListener() && fullscreen"
                        :disabled="saveProgress || progress"
                        :loading="saveProgress"
                        dark
                        text
                        @click="$emit('save')"
                    >
                        <v-icon left>mdi-content-save-outline</v-icon>
                        <div v-if="!save">{{ $t("save") }}</div>
                        <div v-else>{{ save }}</div>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text class="pa-0" v-if="fullscreen && removeMargins">
                <div class="grey--text text--darken-2 subtitle-1">
                    <div v-if="progress" class="d-flex justify-center align-center mt-15 mb-15">
                        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                    </div>
                    
                    <div v-if="!progress" class="ma-0">
                        <slot></slot>
                    </div>
                </div>
            </v-card-text>

            <v-card-text class="pb-2 pl-5 pr-5" v-if="fullscreen && !removeMargins">
                <div class="grey--text text--darken-2 subtitle-1">
                    <div v-if="progress" class="d-flex justify-center align-center mt-15 mb-15">
                        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                    </div>

                    <div v-if="!progress" class="mb-4 mt-4">
                        <slot></slot>
                    </div>
                </div>
            </v-card-text>

            <v-card-text class="pb-2 pl-5 pr-5" v-if="!fullscreen">
                <div class="mt-1 grey--text text--darken-2 subtitle-1">
                    <div v-if="progress" class="d-flex justify-center align-center mt-15 mb-15">
                        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                    </div>
                
                    <div v-if="!progress" class="mb-2 mt-5">
                        <slot></slot>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-end pb-4 px-5 pt-0" v-if="!fullscreen">
                <slot name="actions"></slot>

                <v-btn class="pr-3" text v-if="hasCancelListener()" :disabled="saveProgress" @click="$emit('cancel')">
                    <v-icon left>mdi-close</v-icon>
                    <div class="btn-m" v-if="!cancel">{{ $t("cancel") }}</div>
                    <div class="btn-m" v-else>{{ cancel }}</div>
                </v-btn>

                <v-btn class="pr-3" v-if="hasSaveListener() && !progress" :loading="saveProgress" :disabled="saveProgress" @click="$emit('save')" color="primary"> 
                    <v-icon left>mdi-content-save-outline</v-icon>
                    <div class="btn-m" v-if="!save">{{ $t("save") }}</div>
                    <div class="btn-m" v-else>{{ save }}</div>
                </v-btn>

                <v-btn text v-if="hasNolListener()" :disabled="saveProgress" @click="$emit('no')">
                    <div v-if="!no">{{ $t("no") }}</div>
                    <div v-else>{{ no }}</div>
                </v-btn>

                <v-btn text v-if="hasYeslListener()" :disabled="saveProgress" :loading="saveProgress" @click="$emit('yes')">
                    <div v-if="!yes">{{ $t("yes") }}</div>
                    <div v-else>{{ yes }}</div>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
    .btn-m{
        margin-top: 2px;
    }
</style>

<script>
export default {
    components: {
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        icon: {
            type: String,
            default: null
        },

        title: {
            type: String,
            default: null
        },

        save: {
            type: String,
            default: null
        },

        cancel: {
            type: String,
            default: null
        },

        progress: {
            type: Boolean,
            default: false
        },

        saveProgress: {
            type: Boolean,
            default: false
        },

        fullscreen: {
            type: Boolean,
            default: false
        },

        removeMargins: {
            type: Boolean,
            default: false
        },

        maxWidth: {
            type: String,
            default: "600px"
        },

        yes: {
            type: String,
            default: null
        },

        no: {
            type: String,
            default: null
        },
    },

    methods: {
        hasSaveListener() {
            return this.$listeners && this.$listeners.save;
        },

        hasCancelListener() {
            return this.$listeners && this.$listeners.cancel;
        },

        hasYeslListener() {
            return this.$listeners && this.$listeners.yes;
        },

        hasNolListener() {
            return this.$listeners && this.$listeners.no;
        }
    }
};
</script>
