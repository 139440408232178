export default function(form, schema, error_callback) {
    const v = schema.validate(form, {
        abortEarly: false
    });

    if (v.error) {
        for (let e of v.error.details) {
            let key = e.context.label;
            let type = e.type;
            let limit = e.context.limit;

            type = type.replace(/[^a-zA-Z0-9]/g, "_");

            if (error_callback) error_callback(key, type, limit);
        }

        return false;
    }

    return v.value;
}
