<template>
    <v-container fluid class="pa-0">
        <v-img height="130px" src="../../assets/img/bg.jpg">
            <v-container fluid fill-height class="pl-6">
                <div class="d-flex justify-start align-center">
                    <v-avatar size="64" v-if="avatar" class="mr-5">
                        <v-gravatar :email="avatar" protocol="https" default-img="mm" />
                    </v-avatar>

                    <v-icon color="white" size="48" class="mr-5" v-if="icon">
                        {{icon}}
                    </v-icon>

                    <p class="display-1 grey--text text--lighten-3 ma-0">{{ title ? title : undefined }}</p>
                </div>

                <p class="grey--text text--lighten-2 ma-0" v-if="desc">
                    {{ desc }}
                </p>
            </v-container>
        </v-img>

        <v-card class="rounded-0" elevation="1" v-if="items.length > 1">
            <v-card-text class="pa-0 pt-1">
                <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "No name"
        },

        desc: {
            type: String,
            default: null
        },

        avatar: {
            type: String,
            default: null
        },

        icon: {
            type: String,
            default: null
        }
    },

    watch: { 
        title: function(newVal, oldVal) {
            this.items[this.items.length - 1].text = newVal;
        }
    },

    mounted(){
        const routefrom = this.$route.params.routefrom;

        if(routefrom){
            let routes = routefrom.split("+");

            //calls+call.61b9f8f8463edd3134540886

            for(let r of routes){
                let split = r.split(".");
                let to = { name: (split[1]) ? split[0] : r };

                if(split[1] && routes.length > 1){
                    to = { name: split[0], params: { id: split[1], routefrom: this.items[0].to.name } };
                    //href + "/" + this.items[0].to.path
                }

                this.items.push({
                    text: this.$t("routes."+split[0]),
                    disabled: false,
                    to: to
                });
            }
        }

        this.items.push({
            text: this.title,
            disabled: false
        });
    },

    data: () => ({
        items: [],
    }),
};
</script>
